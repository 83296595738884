/* FlashcardCell.css */

/* Contenedor general de la tarjeta */
.flashcard-cell {
  perspective: 1000px; /* un poco mayor para mejor efecto */
  margin: 0 auto;
}

/* Contenedor interno que rota al hacer flip */
.flashcard-cell-inner {
  position: relative;
  width: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
}

/* Cuando está flipped, rotar 180° */
.flashcard-cell.flipped .flashcard-cell-inner {
  transform: rotateY(180deg);
}

/* Cara frontal y trasera de la tarjeta */
.flashcard-cell-front,
.flashcard-cell-back {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  width: 100%;
  height: auto; /* Permite crecer según el contenido */
  backface-visibility: hidden;
  box-sizing: border-box;
  display: block;
  /* Para que el texto pueda partirse en varias líneas */
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word; /* o 'anywhere' si quieres forzar más */
  font-size: 16px;
  line-height: 1.5;
}

/* Cara frontal */
.flashcard-cell-front {
  background-color: #fff;
  color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Cara trasera */
.flashcard-cell-back {
  background-color: #f0f0f0;
  color: #000;
  transform: rotateY(180deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s forwards;
}

/* Contenido del Modal */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

/* Estilos generales de la tabla y botones */
table {
  border-collapse: separate; /* Permite separar celdas */
}

button {
  outline: none;
  cursor: pointer;
}